<template>
  <ModalContainer @cancel="close">
    <div class="bg-white shadow rounded-lg w-1/2">
      <div class="border-b border-gray-200 px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ $t("users.create_user") }}
        </h3>
      </div>
      <Form @submit="createUser()" v-slot="{ errors }">
        <div v-if="!loading" class="px-4 py-5 sm:p-6">
          <InputField
            :title="$t('users.firstname')"
            v-model="user.first_name"
            :required-input="true"
            rules="required|min:2|max:255"
          />
          <InputField
            :title="$t('users.lastname')"
            v-model="user.last_name"
            :required-input="true"
            rules="required|min:2|max:255"
          />
          <InputField
            :title="$t('users.email')"
            v-model="user.email"
            :required-input="true"
            rules="required|email"
          />
          <label
            for="selector"
            class="block text-sm font-medium leading-5 text-gray-700"
          >
            {{ `${$t("users.roles")}` }}
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2 mb-8">
            <div class="max-w-full rounded-md shadow-sm">
              <v-select
                v-model="user.roles"
                :options="roles"
                label="label"
                :multiple="true"
                :reduce="(role) => role.id"
                :selectable="(option) => !user.roles.includes(option)"
              >
              </v-select>
            </div>
          </div>
          <div class="border-t border-gray-200 pt-6">
            <div class="flex flex-row justify-end">
              <Button
                class="mr-3"
                type="submit"
                button-type="primary"
                :invalid="Object.keys(errors).length !== 0"
                :title="$t('users.save')"
              />
              <Button
                type="button"
                button-type="secondary"
                :title="$t('users.cancel')"
                @clickAction="close"
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  </ModalContainer>
</template>

<script>
import { showNotification } from "@/services/notificationService.js";
import InputField from "@/components/form/InputField";
import Button from "@/components/form/Button";
import ModalContainer from "@/components/helpers/ModalContainer";
import { allRoles } from "@/config";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const usersRepository = RepositoryFactory.get("user");
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { mapState } from "vuex";

export default {
  name: "CreateUserModal",
  components: {
    InputField,
    Button,
    ModalContainer,
  },
  emits: ["close"],
  data: () => {
    return {
      roles: [],
      user: {
        first_name: "",
        last_name: "",
        email: "",
        roles: [],
      },
      loading: false,
    };
  },
  computed: {
    ...mapState("userStore", ["currentUser"]),
  },
  mounted() {
    this.roles = allRoles
      .filter((role) => {
        return this.currentUser.roles_reachable.includes(role);
      })
      .map((role) => {
        return {
          id: role,
          label: this.$t(`roles.${role}`),
        };
      });

    this.user.roles = ["ROLE_STUDENT"];
  },
  methods: {
    close() {
      this.$emit("close");
    },
    createUser() {
      usersRepository
        .inviteNewUser(this.user)
        .then(() => {
          showNotification(
            this.$t("notifications.user_invited"),
            this.$t("notifications.user_is_successfully_invited"),
            "success",
          );
          this.$emit("getUsers");
          this.$emit("close");
        })
        .catch((err) => {
          if (err.response.status === 422) {
            showNotification(
              this.$t("notifications.something_went_wrong_creating_user"),
              Object.values(err.response.data.errors).flat().join(" "),
              "error",
            );
          } else {
            errorHandlingAndMessage(
              err,
              this.$t("notifications.something_went_wrong_creating_user"),
            );
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style></style>
