<template>
  <PageContentContainer>
    <div class="grid grid-cols-1 gap-6">
      <ListHeader
        :buttons="editButtonSettings"
        @editCustomer="
          $router.push({
            name: 'dealersEdit',
            props: { create: true },
          })
        "
      />
      <div
        v-if="dealer"
        class="backgroundRow shadow overflow-hidden sm:rounded-lg"
      >
        <div class="px-4 py-5 sm:px-6">
          <div
            class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
          >
            <div class="ml-4 mt-2 flex flex-row">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-100">
                  {{ $t("dealers.dealership_users") }}
                </h3>
                <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-300">
                  {{ $t("dealers.dealer_details_and_application") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-5 sm:p-0">
          <dl>
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 backgroundRow rounded-none"
            >
              <dt class="text-sm leading-5 font-normal text-gray-300">
                {{ $t("dealers.name") }}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-100 sm:mt-0 sm:col-span-2 font-medium"
              >
                {{ dealer.name }}
              </dd>
            </div>
            <div
              class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4"
            >
              <dt class="text-sm leading-5 font-normal text-gray-300">
                {{ $t("dealers.client_id") }}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-100 sm:mt-0 sm:col-span-2 font-medium"
              >
                {{ dealer.client_id }}
              </dd>
            </div>
            <div
              class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 backgroundRow rounded-none"
            >
              <dt class="text-sm leading-5 font-normal text-gray-300">
                {{ $t("dealers.enabled") }}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-100 sm:mt-0 sm:col-span-2"
              >
                <span
                  :class="{
                    'bg-green-300 text-green-600': dealer.enabled,
                    'bg-red-300 text-red-600': !dealer.enabled,
                  }"
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-300 text-green-600"
                >
                  {{ dealer.enabled ? "enabled" : "disabled" }}
                </span>
              </dd>
            </div>
            <div
              class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4"
            >
              <dt class="text-sm leading-5 font-normal text-gray-300">
                {{ $t("dealers.owner") }}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-100 sm:mt-0 sm:col-span-2 font-medium"
              >
                {{ dealer.owner }}
              </dd>
            </div>
            <div
              class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4 backgroundRow rounded-none"
            >
              <dt class="text-sm leading-5 font-normal text-gray-300">
                {{ $t("dealers.start_date") }}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-100 sm:mt-0 sm:col-span-2 font-medium"
              >
                {{ dealer ? formatDate(dealer.start_date) : "" }}
              </dd>
            </div>
            <div
              class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4"
            >
              <dt class="text-sm leading-5 font-normal text-gray-300">
                {{ $t("dealers.stop_date") }}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-100 sm:mt-0 sm:col-span-2 font-medium"
              >
                {{ dealer ? formatDate(dealer.stop_date) : "" }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <ListHeader
        :search="searchSettings"
        :buttons="buttonSettings"
        @addNewUser="showAddNewUserModal = true"
        @addExistingUser="showAddExistingUserModal = true"
        @searchAction="searchAction"
      />
      <div class="backgroundRow shadow overflow-hidden sm:rounded-md">
        <div class="px-4 py-5 sm:px-6">
          <div
            class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
          >
            <div class="ml-4 mt-2 flex flex-row">
              <h3 class="text-lg leading-6 font-medium text-gray-100">
                {{ $t("dealers.dealership_users") }}
              </h3>
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-blue-200 text-blue-900 ml-2"
              >
                {{ userCount }}
              </span>
            </div>
            <div class="ml-4 mt-2 flex-shrink-0">
              <Button
                v-if="showAddUserButton"
                button-type="primary"
                :title="$t('dealers.add_user')"
                @clickAction="showAddUserModal = true"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div
            class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div class="align-middle inline-block min-w-full overflow-hidden">
              <table class="min-w-full">
                <thead class="backgroundRow">
                  <tr>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-300 uppercase tracking-wider"
                    >
                      {{ $t("dealers.firstname") }}
                    </th>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-300 uppercase tracking-wider"
                    >
                      {{ $t("dealers.lastname") }}
                    </th>
                    <th
                      class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-300 uppercase tracking-wider"
                    >
                      {{ $t("dealers.email") }}
                    </th>
                    <th class="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  <TableRowUsers
                    v-for="user in users[currentPage - 1]"
                    :key="user.id"
                    :firstname="user.firstname"
                    :lastname="user.lastname"
                    :email="user.email"
                    @delete="deleteUserFromDealer(user)"
                    @to="toUser(user.id)"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        class="justify-self-end"
        :total-pages="totalPages"
        :current-page="currentPage"
        :max-result="maxResult"
        @next="currentPage++"
        @previous="currentPage--"
        @toPage="toPage"
        @changeAmountToShow="changeUsersToShow"
      />
    </div>
    <CreateUserModal
      v-if="showAddNewUserModal"
      @update="getDealer"
      @close="showAddNewUserModal = false"
    />
    <AddUserModal
      v-if="showAddExistingUserModal"
      :dealer="dealer.id"
      @update="getDealer"
      @close="showAddExistingUserModal = false"
    />
  </PageContentContainer>
</template>

<script>
import { format } from "date-fns";
import PageContentContainer from "@/components/helpers/PageContentContainer";
import TableRowUsers from "./components/TableRowUsers";
import Button from "@/components/form/Button";
import CreateUserModal from "@/components/helpers/modals/CreateUserModal";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { showNotification } from "@/services/notificationService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const dealerRepository = RepositoryFactory.get("dealer");
const userRepository = RepositoryFactory.get("user");
import ListHeader from "@/components/helpers/ListHeader";
import Pagination from "@/components/helpers/Pagination";
import AddUserModal from "@/components/helpers/modals/dealerModals/AddUserModal.vue";

export default {
  name: "DealersDetail",
  components: {
    AddUserModal,
    PageContentContainer,
    TableRowUsers,
    Button,
    ListHeader,
    CreateUserModal,
    Pagination,
  },
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data: () => {
    return {
      showAddNewUserModal: false,
      showAddExistingUserModal: false,
      showAddUserButton: false,
      showAddUserModal: false,
      showAddCustomerModal: false,
      dealer: {},
      maxResult: parseInt(
        localStorage.getItem("maxresult-dealers-details") || 7,
      ),
      users: [],
      userCount: 0,
      dealerUsers: [],
      searchInput: "",
      totalPages: null,
      totaldealersUsers: null,
      currentPage: 1,
      buttonSettings: [
        {
          title: "button.new_user",
          cy: "addNewUserToDealer",
          action: "addNewUser",
        },
        {
          title: "button.existing",
          cy: "addExistingUserToDealer",
          action: "addExistingUser",
        },
      ],
      editButtonSettings: [
        {
          title: "customers.edit",
          cy: "editCustomer",
          action: "editCustomer",
        },
      ],
      searchSettings: {
        cy: "searchBar",
      },
    };
  },

  created() {
    if (this.id) {
      this.getDealer();
    } else {
      this.$router.push({
        name: "dealers",
      });
    }
  },

  methods: {
    formatDate(date) {
      if (date) {
        return format(
          new Date(date),
          this.$i18n.locale === "en" ? "dd MMM yyyy" : "dd-MM-yyyy",
        );
      } else {
        return "";
      }
    },
    toUser(id) {
      this.$router.push({
        name: "usersDetail",
        params: { id: id },
      });
    },
    deleteUserFromDealer(user) {
      user.dealer_id = null;
      userRepository
        .updateUser(user)
        .then(() => {
          this.getDealer();
          showNotification(
            this.$t("notifications.user_deleted"),
            this.$t("notifications.user_is_successfully_deleted"),
            "success",
          );
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_updating_this_dealer"),
          );
        });
    },
    getDealer() {
      dealerRepository
        .getDealer(this.id)
        .then((result) => {
          this.dealer = result.data.data;
          this.userCount = this.dealer.users.data.length;
          this.totalPages = Math.round(
            result.data.data.users.data.length / this.maxResult,
          );
          this.totaldealersUsers = result.data.data.users.data;
          this.getPages(this.totaldealersUsers);
        })
        .catch(() => {
          this.$router.push({
            name: "dealers",
          });
        });
    },
    searchAction(s) {
      this.users = [];
      this.currentPage = 1;
      const searchInput = s;
      const result = this.totaldealersUsers.filter((customer) => {
        if (
          customer.firstname.toUpperCase().includes(searchInput.toUpperCase())
        ) {
          return customer;
        }
      });
      this.totalPages = Math.round(result.length / this.maxResult);
      this.getPages(result);
    },
    changeUsersToShow(val) {
      this.maxResult = val;
      localStorage.setItem("maxresult-dealers-details", val);
      this.getDealer();
    },
    toPage(p) {
      this.currentPage = p;
    },
    getPages(users) {
      this.users = [];
      for (let i = 0, len = users.length; i < len; i += this.maxResult) {
        this.users.push(users.slice(i, i + this.maxResult));
      }
    },
  },
};
</script>

<style></style>
