import Repository from "./Repository";
import ApiV4Repository from "@/repository/ApiV4Repository";

const V3Resource = "/api/v3/users";
const V4Resource = "/api/v4/users";

export default {
  getUsers({ page, sort, sortfield, maxResult, query }) {
    return Repository.get(
      `${V3Resource}?page=${page}&max_results=${maxResult}&sort_direction=${sort}&sort_by_field=${sortfield}&search_query=${query}`,
    );
  },

  getAllUsers() {
    return Repository.get(`${V3Resource}`);
  },

  getUser(id) {
    return Repository.get(`${V3Resource}/${id}`);
  },

  getCurrentUser() {
    return Repository.get(
      `${V3Resource}/current?include=customer,played-games`,
    );
  },

  getUserResults(payload) {
    return Repository.get(`${V3Resource}/${payload}/results`);
  },

  getUserGamesessions(payload) {
    return Repository.get(`${V3Resource}/${payload}/gamesessions`);
  },

  getMonthlyUsagePeriodForUser(payload, start_date, end_date) {
    return Repository.get(`${V3Resource}/${payload}/reporting/total-playtime`, {
      params: {
        start_date,
        end_date,
      },
    });
  },

  getUserPincode(payload) {
    const { id, training_id } = payload;
    return Repository.get(`${V3Resource}/${id}/pin/${training_id}`);
  },

  activateStudentRole(payload) {
    return Repository.post(`/api/v3/users/activate`, {
      activation_token: payload,
    });
  },

  activate(payload) {
    return Repository.post(`${V3Resource}/classify`, {
      activation_code: payload,
    });
  },

  updateUser(payload) {
    const {
      id,
      firstname,
      lastname,
      email,
      image,
      password,
      dealer_id,
      customer_id,
      roles,
      active,
    } = payload;
    return ApiV4Repository.patch(`${V4Resource}/${id}`, {
      first_name: firstname,
      last_name: lastname,
      email: email,
      password: password,
      image: image,
      dealer_id: dealer_id,
      customer_id: customer_id,
      roles: roles,
      is_active: active,
    });
  },

  inviteNewUser(payload) {
    return ApiV4Repository.post(`${V4Resource}/invite`, payload);
  },

  resendInvite(id) {
    return ApiV4Repository.post(`${V4Resource}/${id}/resend-invite`);
  },

  deleteUser(payload) {
    return Repository.delete(`${V3Resource}/${payload}`);
  },
};
