<template>
  <div
    id="container"
    class="flex justify-center items-center h-screen w-screen lg:py-loginPaddingTopBottom lg:px-loginPaddingLeftRight bg-loginBackgroundColor"
  >
    <div
      id="card"
      class="flex flex-row mobile:h-full w-full max-w-800 h-550 bg-white shadow-lightBg"
    >
      <!-- left side of card -->
      <LeftContainer />
      <!-- right side of card -->
      <div
        id="right"
        class="flex flex-1 flex-col bg-standardBgColor"
        @submit.prevent
        @keypress.enter.prevent="activateUser"
      >
        <div class="flex flex-2 flex-row w-full justify-center items-center">
          <p class="text-title text-darkAlineaColor mobile:hidden">
            {{ $t("auth.account_activation").toUpperCase() }}
          </p>
          <p
            class="text-title text-darkAlineaColor mobile:items-center mobile:justify-center mobile:font-bold mobile:-mt-6 desktop:items-end desktop:mb-inputPadding desktop:ml-inputPadding desktop:hidden"
          >
            {{ $t("auth.virtualstudio") }}
          </p>
        </div>

        <div class="flex flex-4 justify-center items-center desktop:hidden">
          <img
            class="object-contain h-100p"
            src="../../assets/theme/logo.png"
            :alt="$t('auth.virtualstudio')"
          />
        </div>

        <div
          v-if="response"
          id="form-container"
          class="flex flex-9 mobile:flex-3 justify-center items-center flex-col px-inputPaddingLeftRight"
        >
          <p class="text-17 text-darkAlineaColor text-center">
            {{
              response === "succeed"
                ? $t("auth.activation_succes_message")
                : $t("auth.activation_error_message")
            }}
          </p>
          <Button
            v-if="response === 'succeed'"
            class="mb-2 mt-60"
            :title="$t('auth.login')"
            button-type="primary"
            :loading="loading"
            @clickAction="toLogin"
          />
          <Button
            v-if="response === 'failed'"
            class="mb-inputPadding mt-60"
            :title="$t('auth.register')"
            button-type="primary"
            :loading="loading"
            @clickAction="toRegister"
          />
        </div>
        <div
          v-else-if="resetPassword"
          id="form-container"
          class="flex flex-9 mobile:flex-3 justify-center items-center flex-col px-inputPaddingLeftRight"
        >
          <Form
            @submit="activateUser"
            @keypress.enter="activateUser"
            v-slot="{ errors }"
          >
            <InputField
              class="mt-inputPadding w-full"
              :title="$t('auth.password')"
              type="password"
              placeholder=""
              rules="required|min:8|min_one_upper_case|min_one_number"
              v-model="password"
              :required-input="true"
              @update:modelValue="showError = false"
            />

            <InputField
              class="mt-inputPadding"
              :title="$t('auth.repeat_password')"
              type="password"
              placeholder=""
              rules="required|min:8|min_one_upper_case|min_one_number"
              v-model="repeatPassword"
              :required-input="true"
              @update:modelValue="showError = false"
            />

            <ErrorMessage
              v-if="showError"
              class="mt-2"
              :error-message="responseError"
              :password-validation="passwordValidation"
            />

            <Button
              class="mb-inputPadding float-right max-w-400"
              :title="$t('auth.activate_account')"
              button-type="primary"
              width="full"
              type="submit"
              :invalid="Object.keys(errors).length !== 0"
              :loading="loading"
            />
          </Form>
        </div>
        <div v-else></div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftContainer from "@/components/authentication/LeftContainer";
import Button from "@/components/form/Button";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import InputField from "@/components/form/InputField.vue";
const authRepository = RepositoryFactory.get("auth");

export default {
  name: "ActivationCode",
  components: { InputField, LeftContainer, Button },
  props: {
    id: {
      type: Number,
      required: true,
    },
    token: {
      type: String,
      default: "",
    },
    resetPassword: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    response: false,
    loading: false,
    password: "",
    repeatPassword: "",
  }),
  mounted() {
    if (!this.resetPassword) {
      this.activateUser();
    }
  },
  methods: {
    toRegister() {
      this.loading = true;
      this.$router.push({ name: "register" });
    },
    toLogin() {
      this.loading = true;
      this.$router.push({ name: "login" });
    },
    activateUser() {
      const routeSplit = this.$route.fullPath.split("/");
      const payload = {
        password: this.password,
        password_confirmation: this.repeatPassword,
      };
      authRepository
        .confirmActivationToken(
          this.id,
          routeSplit[routeSplit.length - 1],
          payload,
        )
        .then(() => {
          this.response = "succeed";
        })
        .catch(() => {
          this.response = "failed";
        });
    },
  },
};
</script>

<style scoped></style>
