import ApiV4Repository from "@/repository/ApiV4Repository";
const resource = "/api";

export default {
  createUserToken(payload) {
    return ApiV4Repository.post(`${resource}/jwt/login`, payload);
  },

  confirmActivationToken(id, hash, payload) {
    return ApiV4Repository.post(
      `/api/v4/users/${id}/verify-email/${hash}`,
      payload,
    );
  },

  registerNewUser(payload) {
    return ApiV4Repository.post(`/api/v4/users/register`, payload);
  },

  resetPassword(payload) {
    return ApiV4Repository.post(`/api/v4/users/reset-password`, {
      email: payload,
    });
  },

  newPassword(payload) {
    return ApiV4Repository.patch(`/api/v4/users/reset-password`, payload);
  },
};
